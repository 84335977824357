@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.coffee-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.coffee-marker-inner {
    background-color: white;
    border: 2px solid #8B4513;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
}

.coffee-marker-inner:hover {
    transform: scale(1.1);
    background-color: #8B4513;
}
