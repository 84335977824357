.highlight {
    @apply relative inline-block px-2 py-1 mx-1;
    background: linear-gradient(-45deg, #603831, #8B4513, #A0522D);
    background-size: 400% 400%;
    background-position: 0% 50%;
    @apply text-white rounded-md font-medium;
}

.highlight:hover, .highlight.active {
    background: linear-gradient(-45deg, #8B4513, #A0522D, #D2691E);
    animation: gradient 1.5s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
} 

html {
  scroll-padding-top: 5rem; /* 20 in tailwind units */
  scroll-behavior: smooth;
} 